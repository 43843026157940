import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-campaign-rules",
  templateUrl: "./campaign-rules.component.html",
  styleUrls: ["./campaign-rules.component.scss"]
})
export class CampaignRulesComponent implements OnInit {
  listItems: string[] = [
    "Codice univoco Bonus",
    "Il Bonus è valido solo se consegnato al Negozio Salute (Farmacia, Parafarmacia, Sanitaria, ecc) aderente all’iniziativa." +
      " NON È VALIDO PER PROMOZIONI INTERNET O ACQUISTI ON LINE.",
    "Il Bonus è valido solo per l’acquisto del prodotto MICROLIFE AFIB ADVANCED." +
      " NON È VALIDO PER ALTRI PRODOTTI MICROLIFE ANCHE SE DOTATI DI TECNOLOGIA AFIB.",
    "È possibile scaricare solo 1 Bonus per persona maggiorenne VIETATO AI MINORI DI 18 ANNI",
    "È possibile utilizzare il Bonus per 1 solo acquisto."
  ];

  constructor() {}

  ngOnInit() {}
}
