import { PromoReturn } from './promo-return';

export class CustomerReturn {

    constructor(public Company: string,
                public CustomerID: number,
                public AddressID: number,
                public OriginalCode: string,
                public Address: string,
                public ZipCode: string,
                public State: string,
                public City: string,
                public Email: string,
                public Phone: string,
                public AddressOriginalCode: string,
                public PromoActive:PromoReturn[]) { }
}
