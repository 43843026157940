import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ConnectorService} from '../../services/connector.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../services/alert.service';
import {OperationResult} from '../../models/operation-result';
import {CustomerReturn} from '../../models/customer-return';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import {AuthenticationFailedError} from '../../errors/authentication-failed.error';

@Component({
  selector: 'app-reseller-registration-confirm',
  templateUrl: './reseller-registration-confirm.component.html',
  styleUrls: ['./reseller-registration-confirm.component.scss']
})
export class ResellerRegistrationConfirmComponent implements OnInit {

  loading = false;

  registrationFormValues: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private connector: ConnectorService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.registrationFormValues = JSON.parse(this.router.getCurrentNavigation().extras.state.registrationFormValues);
      }
    });

  }

  async ngOnInit() {

    if (!this.registrationFormValues) {
      await this.router.navigateByUrl('/reseller-registration', { replaceUrl: true });
    }

  }

  async requestNewCustomerRegistration() {

    if (this.registrationFormValues) {

      this.loading = true;

      await this.requestNewCustomer(
        this.registrationFormValues.company,
        this.registrationFormValues.vatNumber,
        this.registrationFormValues.sdiCode,
        this.registrationFormValues.pec || '',
        this.registrationFormValues.street,
        this.registrationFormValues.city,
        this.registrationFormValues.postcode,
        this.registrationFormValues.province,
        this.registrationFormValues.phone,
        this.registrationFormValues.email);

    }

  }

  private async requestNewCustomer(company: string, vatNumber: string, sdiCode: string,
                                   pec: string, address: string, city: string, zip: string,
                                   state: string, phone: string, email: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.requestNewCustomer(
          company, vatNumber, sdiCode, pec, address,
          city, zip, state, phone, email)
          .then(async (data: OperationResult<CustomerReturn>) => {

            this.loading = false;

            if (data.Result && data.Result instanceof CustomerReturn) {

              return await this.alertService.confirm(
                this.viewRef,
                'Cliente già registrato',
                'Vuoi richiedere il recupero del tuo codice cliente?',
                () => true,
                () => {
                  this.router.navigateByUrl('/reseller-request-code', { replaceUrl: true });
                  return true;
                });

            }

            await this.router.navigateByUrl('/reseller-registration-success');

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {

              return await this.requestNewCustomer(
                company, vatNumber, sdiCode, pec, address,
                city, zip, state, phone, email, false);

            }

            this.loading = false;

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      }).catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {

          return await this.requestNewCustomer(
            company, vatNumber, sdiCode, pec, address,
            city, zip, state, phone, email, false);

        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
