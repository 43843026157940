import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ConnectorService} from '../../services/connector.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../services/storage.service';
import {AlertService} from '../../services/alert.service';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';

@Component({
  selector: 'app-reseller-change-password',
  templateUrl: './reseller-change-password.component.html',
  styleUrls: ['./reseller-change-password.component.scss']
})
export class ResellerChangePasswordComponent implements OnInit {

  loading = false;

  customerCode: string;
  emailAddress: string;

  changePasswordForm = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    password: new FormControl('', Validators.compose([
      Validators.pattern(/^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i), Validators.required ])),
    passwordConfirm: new FormControl('', Validators.compose([
      Validators.pattern(/^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i), Validators.required ]))
  }, (control: AbstractControl) => {

    if (control.get('password').value !== control.get('passwordConfirm').value) {
      control.get('passwordConfirm').setErrors({ match: true });
    }

    return null;

  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private connector: ConnectorService,
              private storageService: StorageService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.customerCode = this.router.getCurrentNavigation().extras.state.customerCode;
        this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
      }
    });

  }

  async ngOnInit() {

    if (!this.customerCode || this.customerCode === ''
      || !this.emailAddress || this.emailAddress === ''
      || !await this.storageService.hasCredentials()) {
      await this.router.navigateByUrl('/reseller-dashboard', { replaceUrl: true });
    }

  }

  async submitChangePasswordForm() {

    if (this.changePasswordForm.valid) {

      this.loading = true;

      await this.changeUserPassword(
        this.customerCode,
        this.emailAddress,
        this.changePasswordForm.controls.password.value);

    }

  }

  private async changeUserPassword(code: string, email: string, password: string, retry: boolean = true) {

    const credentials = await this.storageService.getCredentials();

    await this.connector.reAuthenticate(credentials.token)
      .then(async () => {

        await this.connector.changeUserPassword(code, email, password)
          .then(async () => {

            credentials.password = password;

            await this.storageService.setCredentials(credentials);

            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              'Cambio password eseguito correttamente',
              () => {
                this.router.navigateByUrl('/reseller-dashboard', { replaceUrl: true });
                return true;
              });

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.changeUserPassword(code, email, password, false);
            }

            this.loading = false;

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      }).catch(async (error) => {

        if (error instanceof NotAuthenticatedError && retry) {
          return await this.changeUserPassword(code, email, password, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
