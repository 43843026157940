import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConnectorService} from '../../services/connector.service';
import {AlertService} from '../../services/alert.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationFailedError} from '../../errors/authentication-failed.error';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';

@Component({
  selector: 'app-code-success',
  templateUrl: './code-success.component.html',
  styleUrls: ['./code-success.component.scss']
})
export class CodeSuccessComponent implements OnInit {

  loading = false;

  couponCode: string;
  emailAddress: string;
  phoneNumber: string;

  showEmailForm = false;
  showSmsForm = false;

  sendEmailForm = new FormGroup({
    emailAddress: new FormControl('', Validators.compose([ Validators.email, Validators.required ]))
  });

  sendSmsForm = new FormGroup({
    phoneNumber: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private connector: ConnectorService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.couponCode = this.router.getCurrentNavigation().extras.state.couponCode;
        this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
        this.phoneNumber = this.router.getCurrentNavigation().extras.state.phoneNumber;
      }
    });

  }

  async ngOnInit() {

    if (!this.couponCode || this.couponCode === '') {
      await this.alertService.error(this.viewRef, 'Codice coupon non valido');
      await this.router.navigateByUrl('/code-request', { replaceUrl: true });
    }

    this.sendEmailForm.controls.emailAddress.setValue(this.emailAddress);
    this.sendSmsForm.controls.phoneNumber.setValue(this.phoneNumber);

    (window as any).dataLayer.push({
      event: 'code.success.bonus35'
    });

  }

  toggleEmailForm() {
    this.showEmailForm = !this.showEmailForm;
    this.showSmsForm = false;
  }

  toggleSmsForm() {
    this.showSmsForm = !this.showSmsForm;
    this.showEmailForm = false;
  }

  async submitSendEmailForm() {

    if (this.sendEmailForm.valid) {
      this.loading = true;
      await this.sendCode(this.couponCode, this.sendEmailForm.controls.emailAddress.value, '');
    }

  }

  async submitSendSmsForm() {

    if (this.sendSmsForm.valid) {
      this.loading = true;
      await this.sendCode(this.couponCode, '', this.sendSmsForm.controls.phoneNumber.value);
    }

  }

  private async sendCode(code: string, email: string, phone: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.sendCode(code, email, phone)
          .then(async () => {

            this.loading = false;

            await this.alertService.success(this.viewRef, 'Codice inviato correttamente');

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.sendCode(code, email, phone, false);
            }

            this.loading = false;

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      })
      .catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {
          return await this.sendCode(code, email, phone, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
