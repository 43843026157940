import { Injectable, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationFailedError, NoCustomerError, NotAuthenticatedError } from '../errors';
import { AuthenticationReturn, CustomerReturn, OperationResult, UserCredentials } from '../models';
import { AlertService } from './alert.service';
import { ConnectorService } from './connector.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    constructor(private storageService: StorageService,
        private connector: ConnectorService,
        private router: Router,
        private alertService: AlertService) {

    }

    public async goToPromoList(viewRef: ViewContainerRef,retry: boolean = true) {

        const credidentials: UserCredentials = this.storageService.getCredentials();
        await this.connector
            .authenticate()
            .then(async () => {
                await this.connector
                    .reAuthenticate(credidentials.token)
                    .then(async (data: OperationResult<AuthenticationReturn>) => {


                        await this.router.navigateByUrl('/promo-list', {
                            state: {
                                promos: JSON.stringify(data.Result.PromoActive)
                            }
                        });
                    })
                    .catch(async error => {
                        if (error instanceof NotAuthenticatedError && retry) {
                            return await this.goToPromoList(viewRef,false);
                        }


                        if (error instanceof NoCustomerError) {
                            return await this.alertService.error(
                                viewRef,
                                'Codice cliente non valido'
                            );
                        }

                        await this.alertService.error(
                            viewRef,
                            'Errore durante il caricamento'
                        );
                    });
            })
            .catch(async error => {
                if (error instanceof AuthenticationFailedError && retry) {
                    return await this.goToPromoList(viewRef,false);
                }
                await this.alertService.error(
                    viewRef,
                    'Errore durante il caricamento'
                );
            });

    }
}