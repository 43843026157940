export class VistaCouponInvoices {

    constructor(public CouponID: number,
                public AddressID: number,
                public InvoiceNumber: string,
                public InvoiceDate: Date,
                public Quantity: number,
                public Description: string,
                public OriginalCode: string,
                public Company: string,
                public AddressOriginalCode: string,
                public CustomerOriginalCode: string) { }

}
