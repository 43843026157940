import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { ResellerCampaignTermsModalComponent } from "../reseller-campaign-terms-modal/reseller-campaign-terms-modal.component";
import { ModalDialogService } from "ngx-modal-dialog";

@Component({
  selector: "app-reseller-help",
  templateUrl: "./reseller-help.component.html",
  styleUrls: ["./reseller-help.component.scss"]
})
export class ResellerHelpComponent implements OnInit {
  constructor(
    private viewRef: ViewContainerRef,
    private modalDialogService: ModalDialogService
  ) {}

  ngOnInit() {}

  openCampaignRulesModal() {
    this.modalDialogService.openDialog(this.viewRef, {
      title: "REGOLAMENTO",
      childComponent: ResellerCampaignTermsModalComponent,
      actionButtons: [
        {
          text: "Accetto",
          onAction: () => true
        }
      ]
    });
  }
}
