export class VistaCouponClientiGeo {

    constructor(public CustomerID: number,
                public Latitude: string,
                public Longitude: string,
                public Company: string,
                public Address1: string,
                public City: string,
                public State: string,
                public Zip: string,
                public Phone: string,
                public Email: string,
                public AddressID: 11,
                public OriginalCode: string,
                public distanceKms: number) { }

}
