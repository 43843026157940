import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectorService } from "../../services/connector.service";
import { AlertService } from "../../services/alert.service";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { StorageService } from "../../services/storage.service";
import { CustomerReturn } from "../../models/customer-return";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { NoCustomerError } from "../../errors/no-customer.error";
import { AlreadyActiveError } from "../../errors/already-active.error";
import { AlreadyRegisteredDifferentEmailError } from "../../errors/already-registered-different-email.error";
import { SendEmailError } from "../../errors/send-email.error";
import { AuthenticationFailedError } from "../../errors/authentication-failed.error";
import { OperationResult } from "../../models/operation-result";
import { CouponRegistration } from "../../models/coupon-registration";
import { formatDate } from "@angular/common";
import { NotAuthorizedError } from "../../errors/not-authorized.error";

@Component({
  selector: "app-reseller-manage-users",
  templateUrl: "./reseller-manage-users.component.html",
  styleUrls: ["./reseller-manage-users.component.scss"]
})
export class ResellerManageUsersComponent implements OnInit {
  loading = false;

  customer: CustomerReturn;

  @ViewChild("deleteBtnTpl", { static: true }) deleteBtnTpl: TemplateRef<any>;

  usersTable = {
    columns: [],
    rows: [],
    columnMode: "flex",
    messages: {
      emptyMessage: "Nessun utente disponibile",
      totalMessage: "Totale"
    }
  };

  createUserForm = new FormGroup(
    {
      title: new FormControl(""),
      name: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      emailConfirm: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.pattern(
            /^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i
          ),
          Validators.required
        ])
      ),
      passwordConfirm: new FormControl(
        "",
        Validators.compose([
          Validators.pattern(
            /^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i
          ),
          Validators.required
        ])
      ),
      position: new FormControl("")
    },
    (control: AbstractControl) => {
      if (control.get("email").value !== control.get("emailConfirm").value) {
        control.get("emailConfirm").setErrors({ match: true });
      }

      if (
        control.get("password").value !== control.get("passwordConfirm").value
      ) {
        control.get("passwordConfirm").setErrors({ match: true });
      }

      return null;
    }
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private connector: ConnectorService,
    private storageService: StorageService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) {
    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.customer = JSON.parse(
          this.router.getCurrentNavigation().extras.state.customer
        );
      }
    });
  }

  async ngOnInit() {
    if (!this.customer || !(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-dashboard", {
        replaceUrl: true
      });
    }

    this.usersTable.columns = [
      { prop: "name", name: "Nome", flexGrow: 4 },
      { prop: "email", name: "Email", flexGrow: 4 },
      { prop: "role", name: "Ruolo", flexGrow: 4 },
      { prop: "regDate", name: "Data registrazione", flexGrow: 3 },
      { prop: "delete", name: "", cellTemplate: this.deleteBtnTpl, flexGrow: 2 }
    ];

    this.loading = true;

    await this.getUsers();
  }

  private async loadData() {
    this.loading = true;

    await this.getUsers();
  }

  private async getUsers(retry: boolean = true) {
    const credentials = await this.storageService.getCredentials();

    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCustomerUsers()
          .then(async (data: OperationResult<CouponRegistration[]>) => {
            const rows = [];

            for (const item of data.Result) {
              rows.push({
                name: item.Name,
                email: item.Email,
                role: item.Job,
                regDate: formatDate(
                  item.RegistrationDate,
                  "dd/MM/yyyy",
                  "it-IT"
                ),
                delete: !item.IsAdmin ? item.RegistrationID : 0
              });
            }

            this.usersTable.rows = rows;

            this.loading = false;
          })
          .catch(async error => {
            console.log(error);

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getUsers(false);
            }

            this.loading = false;

            if (error instanceof NotAuthorizedError) {
              return await this.alertService.error(
                this.viewRef,
                "Non sei autorizzato ad eseguire questa operazione"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getUsers(false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  async submitCreateUserForm() {
    if (this.createUserForm.valid && this.customer) {
      this.loading = true;

      await this.registerCustomer(
        this.createUserForm.controls.email.value,
        this.createUserForm.controls.password.value,
        this.createUserForm.controls.name.value,
        this.customer.AddressOriginalCode,
        this.createUserForm.controls.title.value,
        this.createUserForm.controls.position.value
      );
      this.createUserForm.reset();
    }
  }

  private async registerCustomer(
    email: string,
    password: string,
    name: string,
    code: string,
    title: string,
    job: string,
    retry: boolean = true
  ) {
    await this.connector
      .authenticate()
      .then(async () => {
        await this.connector
          .registerCustomer(email, password, name, code, true, title, job)
          .then(async () => {
            this.loading = false;

            await this.loadData();
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerCustomer(
                email,
                password,
                name,
                code,
                title,
                job,
                false
              );
            }

            this.loading = false;

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice cliente non valido"
              );
            }

            if (error instanceof AlreadyActiveError) {
              return await await this.alertService.error(
                this.viewRef,
                "Indirizzo email già registrato"
              );
            }

            if (error instanceof AlreadyRegisteredDifferentEmailError) {
              return this.alertService.error(
                this.viewRef,
                "Cliente già registrato con un indirizzo email differente"
              );
            }

            if (error instanceof SendEmailError) {
              return await this.loadData();
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.registerCustomer(
            email,
            password,
            name,
            code,
            title,
            job,
            false
          );
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  async deleteUserConfirm(registrationId: number) {
    if (registrationId !== 0) {
      await this.alertService.confirm(
        this.viewRef,
        "Elimina",
        "Sei sicuro di voler eliminare l'utente?",
        () => true,
        () => {
          this.loading = true;
          this.deleteUser(registrationId);
          return true;
        }
      );
    }
  }

  private async deleteUser(registrationId: number, retry: boolean = true) {
    const credentials = await this.storageService.getCredentials();

    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .removeCustomerUser(registrationId)
          .then(async () => {
            this.loading = false;

            await this.loadData();
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.deleteUser(registrationId, false);
            }

            this.loading = false;

            if (error instanceof NotAuthorizedError) {
              return await this.alertService.error(
                this.viewRef,
                "Non sei autorizzato ad eseguire questa operazione"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.deleteUser(registrationId, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }
}
