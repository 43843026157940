export enum ErrorCode {
    None = 0,
    GenericError = 1,
    NotAuthenticated = 2,
    NoCustomer = 3,
    AlreadyActive = 4,
    AlreadyRegisteredDifferentEmail = 5,
    ErrorSendEmail = 6,
    NoCoupon = 7,
    PersonalCodeInvalid = 8,
    CustomerNotActive = 9,
    CouponNotValid = 10,
    CouponExpired = 11,
    CouponAlreadyUsed = 12,
    NotAuthorized = 13,
    PersonalCodeNotMatch = 14
}
