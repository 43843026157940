import { Component, OnInit } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { ConnectorService } from "../../services/connector.service";
import { Router } from "@angular/router";
import { UserCredentials } from "../../models/user-credentials";
import { OperationResult } from "../../models/operation-result";
import { VistaCouponUtilizzi } from "../../models/vista-coupon-utilizzi";
import { formatDate } from "@angular/common";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { VistaCouponInvoices } from "../../models/vista-coupon-invoices";
import { CouponTotal } from 'src/app/models';

@Component({
  selector: "app-reseller-bonus-details",
  templateUrl: "./reseller-bonus-details.component.html",
  styleUrls: ["./reseller-bonus-details.component.scss"]
})
export class ResellerBonusDetailsComponent implements OnInit {
  loading = false;

  mode = "coupons";

  couponsTable = {
    columns: [
      { prop: "dateReg", name: "Data registrazione", sortable: false },
      { prop: "cf", name: "Codice Fiscale" },
      { prop: "code", name: "Codice Bonus" },
      { prop: "orderNr", name: "Accredito" }
    ],
    rows: [],
    columnMode: "force",
    messages: {
      emptyMessage: "Nessun bonus disponibile",
      totalMessage: "Totale"
    }
  };

  invoicesTable = {
    columns: [
      { prop: "number", name: "Fattura numero" },
      { prop: "date", name: "Data Fattura", sortable: false },
      { prop: "qty", name: "Quantità Bonus" }
    ],
    rows: [],
    columnMode: "force",
    messages: {
      emptyMessage: "Nessuna fattura disponibile",
      totalMessage: "Totale"
    }
  };

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private storageService: StorageService
  ) { }



  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true
      });
    }

    await this.loadData();
  }

  private async loadData() {
    this.loading = true;

    const credentials = await this.storageService.getCredentials();

    await this.getCouponsTotal();

    this.loading = false;
  }

  private async getCouponsTotal(retry: boolean = true) {
    const credentials = this.storageService.getCredentials();

    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCouponsTotal()
          .then(async (data: OperationResult<CouponTotal>) => {

            await this.getCoupons(credentials, data.Result);

            await this.getInvoices(credentials);
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCouponsTotal(false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getCouponsTotal(false);
        }
      });
  }

  private async getCoupons(
    credentials: UserCredentials,
    couponsTotal: CouponTotal,
    retry: boolean = true
  ) {
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCouponsUsage()
          .then((data: OperationResult<VistaCouponUtilizzi[]>) => {
            const rows = [];

            // Verifico se c'è un disallineamento tra il numero bonus accreditati
            // per il riepilogo e il numero di accreditati nella lista
            // Se ci sono dei disallineamento segno come accreditati i bonus più vecchi
            let count = couponsTotal.UsedCoupon;
            for (const item of data.Result) {
              if (item.OrderNumber) {
                count--;
              }
            }
            
            if (count > 0) {
              // Se c'è un disallineamento numerico forzo il flag a SI
              data.Result.sort(function (a, b) {
                var c = new Date(a.RegistrationDate);
                var d = new Date(b.RegistrationDate);
                return c.getTime() - d.getTime();
              })

              for (const item of data.Result) {
                if (!item.OrderNumber) {
                  item.OrderNumber = 1;
                  count--;
                }
                if (count == 0) break;
              }
            }

            data.Result.sort(function (a, b) {
              var c = new Date(a.RegistrationDate);
              var d = new Date(b.RegistrationDate);
              return d.getTime() - c.getTime();
            });


            for (const item of data.Result) {
              rows.push({
                cf: item.PersonalCode,
                code: item.CouponCode,
                dateAcq: formatDate(item.UsageDate, "dd/MM/yyyy", "it-IT"),
                orderNr: item.OrderNumber ? "SI" : "NO",
                dateReg: formatDate(
                  item.RegistrationDate,
                  "dd/MM/yyyy",
                  "it-IT"
                )
              });
            }
            this.couponsTable.rows = rows;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getCoupons(credentials, couponsTotal, false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getCoupons(credentials, couponsTotal, false);
        }
      });
  }

  private async getInvoices(
    credentials: UserCredentials,
    retry: boolean = true
  ) {
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .getCouponsInvoices()
          .then((data: OperationResult<VistaCouponInvoices[]>) => {
            const rows = [];

            data.Result.sort(function (a, b) {
              var c = new Date(a.InvoiceDate);
              var d = new Date(b.InvoiceDate);
              return d.getTime() - c.getTime();
            });

            for (const item of data.Result) {
              rows.push({
                number: item.InvoiceNumber,
                date: formatDate(item.InvoiceDate, "dd/MM/yyyy", "it-IT"),
                qty: item.Quantity
              });
            }

            this.invoicesTable.rows = rows;
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.getInvoices(credentials, false);
            }
          });
      })
      .catch(async error => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.getInvoices(credentials, false);
        }
      });
  }

  showCoupons() {
    this.mode = "coupons";
  }

  showInvoices() {
    this.mode = "invoices";
  }
}
