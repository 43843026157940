export class CouponUsage {

    constructor(public CouponID: number,
                public CouponCode: string,
                public UsageDate: Date,
                public UsageType: number,
                public AddressID: number,
                public OrderNumber: number,
                public RegistrationID: number,
                public DiscountedValue: number) { }

}
