export class VistaCouponProdottiDisponibilita {

    constructor(public CustomerID: number,
                public Company: string,
                public Name: string,
                public SKU: string,
                public ProductID: number,
                public Quantity: number,
                public CouponID: number,
                public StartDate: Date,
                public EndDate: Date,
                public MaxUsage: number,
                public CouponValue: number,
                public CouponType: number,
                public Description: string,
                public AddressID: number) { }

}
