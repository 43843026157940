import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as Sentry from '@sentry/browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CampaignInfoComponent } from './components/campaign-info/campaign-info.component';
import { CampaignRulesComponent } from './components/campaign-rules/campaign-rules.component';
import { CodeRequestComponent } from './components/code-request/code-request.component';
import { CodeSuccessComponent } from './components/code-success/code-success.component';
import { HomeComponent } from './components/home/home.component';
import { ResellerAccessComponent } from './components/reseller-access/reseller-access.component';
import { ResellerBonusDetailsComponent } from './components/reseller-bonus-details/reseller-bonus-details.component';
import { ResellerChangePasswordComponent } from './components/reseller-change-password/reseller-change-password.component';
import { ResellerCreateFirstUserComponent } from './components/reseller-create-first-user/reseller-create-first-user.component';
import { ResellerDashboardComponent } from './components/reseller-dashboard/reseller-dashboard.component';
import { ResellerDetailComponent } from './components/reseller-detail/reseller-detail.component';
import { ResellerEmailConfirmComponent } from './components/reseller-email-confirm/reseller-email-confirm.component';
import { ResellerHelpComponent } from './components/reseller-help/reseller-help.component';
import { ResellerLoginComponent } from './components/reseller-login/reseller-login.component';
import { ResellerManageUsersComponent } from './components/reseller-manage-users/reseller-manage-users.component';
import { ResellerPasswordResetComponent } from './components/reseller-password-reset/reseller-password-reset.component';
import { ResellerRegistrationComponent } from './components/reseller-registration/reseller-registration.component';
import { ResellerRegistrationConfirmComponent } from './components/reseller-registration-confirm/reseller-registration-confirm.component';
import { ResellerRegistrationSuccessComponent } from './components/reseller-registration-success/reseller-registration-success.component';
import { ResellerRequestCodeComponent } from './components/reseller-request-code/reseller-request-code.component';
import { ResellerRequestConsumerCodeComponent } from './components/reseller-request-consumer-code/reseller-request-consumer-code.component';
import { ResellerVerifyConsumerCodeComponent } from './components/reseller-verify-consumer-code/reseller-verify-consumer-code.component';
import { ResellerCampaignTermsModalComponent } from './components/reseller-campaign-terms-modal/reseller-campaign-terms-modal.component';
import { ResellersListComponent } from './components/resellers-list/resellers-list.component';
import { SecurityCodeComponent } from './components/security-code/security-code.component';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { ResellerVerifySecurityCodeComponent } from './components/reseller-verify-security-code/reseller-verify-security-code.component';
import { ResellerConsumerCodeSuccessComponent } from './components/reseller-consumer-code-success/reseller-consumer-code-success.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PromoListComponent } from './components/promo-list/promo-list.component';
import { PromoButtonComponent } from './components/promo-button/promo-button.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { VideoTutorialListComponent } from './components/video-tutorial-list/video-tutorial-list.component';
import { VideoTutorialComponent } from './components/video-tutorial/video-tutorial.component';

Sentry.init({
  dsn: 'https://5e1df99b46b2405d8d60af12af8ae26a@sentry.2000net.it//17'
});

registerLocaleData(localeIt, 'it-IT', localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    CampaignInfoComponent,
    CampaignRulesComponent,
    CodeRequestComponent,
    CodeSuccessComponent,
    HomeComponent,
    ResellerAccessComponent,
    ResellerBonusDetailsComponent,
    ResellerChangePasswordComponent,
    ResellerCampaignTermsModalComponent,
    ResellerCreateFirstUserComponent,
    ResellerDashboardComponent,
    ResellerDetailComponent,
    ResellerEmailConfirmComponent,
    ResellerHelpComponent,
    ResellerLoginComponent,
    ResellerManageUsersComponent,
    ResellerPasswordResetComponent,
    ResellerRegistrationComponent,
    ResellerRegistrationConfirmComponent,
    ResellerRegistrationSuccessComponent,
    ResellerRequestCodeComponent,
    ResellerRequestConsumerCodeComponent,
    ResellerVerifyConsumerCodeComponent,
    ResellersListComponent,
    SecurityCodeComponent,
    AlertModalComponent,
    ResellerVerifySecurityCodeComponent,
    ResellerConsumerCodeSuccessComponent,
    PromoListComponent,
    PromoButtonComponent,
    VideoTutorialListComponent,
    VideoTutorialComponent
  ],
  entryComponents: [AlertModalComponent, ResellerCampaignTermsModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    NgxDatatableModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ModalDialogModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3nmMTxxJaa09NsYVOMO4HRIwNvm_QxeQ'
    }),
    AgmDirectionModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    GooglePlaceModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
