import { Component, OnInit } from '@angular/core';
import {Coordinates} from '../../models/coordinates';
import {VistaCouponClientiGeo} from '../../models/vista-coupon-clienti-geo';
import {GeolocationService} from '../../services/geolocation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-reseller-detail',
  templateUrl: './reseller-detail.component.html',
  styleUrls: ['./reseller-detail.component.scss']
})
export class ResellerDetailComponent implements OnInit {

  currentPosition: Coordinates;

  customer: VistaCouponClientiGeo;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private domSanitizer: DomSanitizer,
              private geolocationService: GeolocationService) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.customer = JSON.parse(this.router.getCurrentNavigation().extras.state.customer);
      }
    });

  }

  async ngOnInit() {

    if (!this.customer) {
      await this.router.navigateByUrl('/resellers-list', { replaceUrl: true });
    }

    if (navigator.geolocation) {

      await navigator.geolocation.getCurrentPosition((position) => {
        this.currentPosition = new Coordinates(position.coords.latitude, position.coords.longitude);
      });

    }

  }

  getDistanceKilometers() {

    if (!this.currentPosition || !this.customer
      || !this.customer.Latitude || this.customer.Latitude === ''
      || !this.customer.Longitude || this.customer.Longitude === '') {
      return null;
    }

    const distance = this.geolocationService.distance(
      this.currentPosition.latitude,
      this.currentPosition.longitude,
      +this.customer.Latitude,
      +this.customer.Longitude);

    return Math.floor(distance / 1000);

  }

}
