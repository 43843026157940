import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { VideoTutorial } from "src/app/models/video-tutorial";
import { Location } from '@angular/common'

@Component({
  selector: "app-video-tutorial",
  templateUrl: "./video-tutorial.component.html",
  styleUrls: ["./video-tutorial.component.scss"]
})
export class VideoTutorialComponent implements OnInit {
  public tutorial: VideoTutorial = { title: '', text: '', videoUrl: '', thumbnailUrl: '', };
  constructor(private router: Router, private sanitizer: DomSanitizer, private location: Location) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.tutorial = JSON.parse(
        this.router.getCurrentNavigation().extras.state.tutorial
      );
    }

  }

  async ngOnInit() {

  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public goBack() {
    this.location.back();
  }
}
