import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-campaign-info",
  templateUrl: "./campaign-info.component.html",
  styleUrls: ["./campaign-info.component.scss"]
})
export class CampaignInfoComponent implements OnInit {
  urlLocation = location.origin;
  constructor() {}

  ngOnInit() {
    console.log(location);
  }
}
