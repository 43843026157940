import { Component, ComponentRef } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from "ngx-modal-dialog";

@Component({
  selector: "app-reseller-campaign-terms-modal",
  templateUrl: "./reseller-campaign-terms-modal.component.html",
  styleUrls: ["./reseller-campaign-terms-modal.component.scss"]
})
export class ResellerCampaignTermsModalComponent implements IModalDialog {
  listItems: string[] = [
    "Il Bonus è valido solo per vendite effettuate presso il Negozio Salute registrato all'iniziativa Prevenzione ICTUS." +
      " NON È VALIDO PER VENDITE INTERNET O ACQUISTI ON LINE. ",
    "Il Bonus è valido solo per l’acquisto del prodotto MICROLIFE AFIB ADVANCED." +
      " NON È VALIDO PER ALTRI PRODOTTI MICROLIFE ANCHE SE DOTATI DI TECNOLOGIA AFIB.",
    "Il bonus verrà accreditato solo se appositamente registrato con codice fiscale tramite app o sito www.prevenzioneictus.it.",
    "È possibile registrare e utilizzare il Bonus 1 sola volta. Dopo la registrazione il Bonus viene «bruciato» ",
    "Il Bonus verrà accreditato direttamente nella fattura del primo ordine effettuato con COLPHARMA all’apposita voce « Bonus Campagna Prevenzione ICTUS » " +
      "per un valore pari a 35,00 euro scontato del 60% (sconto lordo) pari a 14,00 euro + IVA." +
      "Comunicare all’agente COLPHARMA di volere ricevere l'accredito dei Bonus.",
    "Il numero di Bonus accreditabili non potrà eccedere il numero di prodotti MICROLIFE AFIB ADVANCED acquistati da" +
      " COLPHARMA o tramite Transfer Order aderente all’iniziativa. Il calcolo dei Bonus accreditabili considererà anche eventuali giacenze valorizzate" +
      " come quantitativo acquistato da COLPHARMA nell’ordine precedente alla data di inizio dell’iniziativa: 8 Luglio 2019.",
    "Non possono essere conteggiati per l’iniziativa eventuali prodotti NON acquistati da COLPHARMA, tramite Transfer Order non aderente" +
      " alla campagna, acquisti da grossisti o altro in quanto non tracciabili da parte di COLPHARMA.",
    "Il numero di Bonus registrati, accreditati e da accreditare sarà riportato nell’apposita sezione" +
      " della propria utenza tramite APP o sito www.prevenzioneictus.it"
  ];

  constructor() {}

  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<any>>
  ) {}
}
