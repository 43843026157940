import { Component, OnInit } from '@angular/core';
import {CustomerReturn} from '../../models/customer-return';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reseller-email-confirm',
  templateUrl: './reseller-email-confirm.component.html',
  styleUrls: ['./reseller-email-confirm.component.scss']
})
export class ResellerEmailConfirmComponent implements OnInit {

  customer: CustomerReturn;

  emailAddress: string = 'andrea.boggio@2000net.it';

  constructor(private router: Router,
              private route: ActivatedRoute) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.customer = JSON.parse(this.router.getCurrentNavigation().extras.state.customer);
        this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
      }
    });

  }

  async ngOnInit() {

    /*if (!this.customer || !this.emailAddress || this.emailAddress === '') {
      await this.router.navigateByUrl('/reseller-access', { replaceUrl: true });
    }*/

  }

}
