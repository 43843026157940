import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../services/alert.service';
import {ConnectorService} from '../../services/connector.service';
import {Router} from '@angular/router';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import {AuthenticationFailedError} from '../../errors/authentication-failed.error';

@Component({
  selector: 'app-reseller-request-code',
  templateUrl: './reseller-request-code.component.html',
  styleUrls: ['./reseller-request-code.component.scss']
})
export class ResellerRequestCodeComponent implements OnInit {

  loading = false;

  requestCodeForm = new FormGroup({
    company: new FormControl('', Validators.required),
    vatNumber: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([ Validators.email, Validators.required ])),
    phone: new FormControl('', Validators.required),
  });

  constructor(private router: Router,
              private connector: ConnectorService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) { }

  ngOnInit() { }

  async submitRequestCodeForm() {

    if (this.requestCodeForm.valid) {

      this.loading = true;

      await this.requestCode(
        this.requestCodeForm.controls.company.value,
        this.requestCodeForm.controls.vatNumber.value,
        this.requestCodeForm.controls.name.value,
        this.requestCodeForm.controls.email.value,
        this.requestCodeForm.controls.company.value);

    }

  }

  private async requestCode(company: string, vatNumber: string, name: string,
                            email: string, phone: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.requestCustomerCode(company, vatNumber, name, email, phone)
          .then(async () => {

            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              'Richiesta inviata correttamente',
              () => {
                this.router.navigateByUrl('/reseller-access');
                return true;
              });

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.requestCode(company, vatNumber, name, email, phone, false);
            }

            this.loading = false;

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');


          });

      }).catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {
          return await this.requestCode(company, vatNumber, name, email, phone, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
