import { Component, OnInit, ViewContainerRef } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { CustomerReturn } from "../../models/customer-return";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectorService } from "../../services/connector.service";
import { AlertService } from "../../services/alert.service";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { NoCustomerError } from "../../errors/no-customer.error";
import { AlreadyActiveError } from "../../errors/already-active.error";
import { AlreadyRegisteredDifferentEmailError } from "../../errors/already-registered-different-email.error";
import { SendEmailError } from "../../errors/send-email.error";
import { AuthenticationFailedError } from "../../errors/authentication-failed.error";
import { ModalDialogService } from "ngx-modal-dialog";
import { ResellerCampaignTermsModalComponent } from "../reseller-campaign-terms-modal/reseller-campaign-terms-modal.component";
import { ToastrService } from "ngx-toastr";
import { PROMOTION_ID } from 'src/app/app.settings';

@Component({
  selector: "app-reseller-create-first-user",
  templateUrl: "./reseller-create-first-user.component.html",
  styleUrls: ["./reseller-create-first-user.component.scss"]
})
export class ResellerCreateFirstUserComponent implements OnInit {
  loading = false;

  customer: CustomerReturn;

  createFirstUserForm = new FormGroup(
    {
      title: new FormControl(""),
      name: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      emailConfirm: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.pattern(
            /^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i
          ),
          Validators.required
        ])
      ),
      passwordConfirm: new FormControl(
        "",
        Validators.compose([
          Validators.pattern(
            /^(?=^.{8,}$)(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i
          ),
          Validators.required
        ])
      ),
      position: new FormControl(""),
      storeLocator: new FormControl(true, Validators.requiredTrue),
      acceptance: new FormControl(false, Validators.requiredTrue)
    },
    (control: AbstractControl) => {
      if (control.get("email").value !== control.get("emailConfirm").value) {
        control.get("emailConfirm").setErrors({ match: true });
      }

      if (
        control.get("password").value !== control.get("passwordConfirm").value
      ) {
        control.get("passwordConfirm").setErrors({ match: true });
      }

      return null;
    }
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private connector: ConnectorService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private modalDialogService: ModalDialogService,
    private toastr: ToastrService
  ) {
    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.customer = JSON.parse(
          this.router.getCurrentNavigation().extras.state.customer
        );
      }
    });
  }

  async ngOnInit() {
    if (!this.customer) {
      await this.router.navigateByUrl("/reseller-access", { replaceUrl: true });
    }
  }

  public isCustomerWithOtherPromo(): boolean {
    let otherPromo = false;
    let thisPromo = false
    if (this.customer && this.customer.PromoActive) {
      this.customer.PromoActive.forEach(p => {
        if (p.Id != PROMOTION_ID) {
          otherPromo = true;
        }
        else {
          thisPromo = true;
        }
      })
    }

    return !thisPromo && otherPromo;
  }

  async submitCreateFirstUserForm() {
    if (this.createFirstUserForm.valid && this.customer) {
      this.loading = true;

      await this.registerCustomer(
        this.createFirstUserForm.controls.email.value,
        this.createFirstUserForm.controls.password.value,
        this.createFirstUserForm.controls.name.value,
        this.customer.AddressOriginalCode,
        this.createFirstUserForm.controls.storeLocator.value,
        this.createFirstUserForm.controls.title.value,
        this.createFirstUserForm.controls.position.value
      );
    }
  }
  private async registerCustomer(
    email: string,
    password: string,
    name: string,
    code: string,
    geo: boolean,
    title: string,
    job: string,
    retry: boolean = true
  ) {
    await this.connector
      .authenticate()
      .then(async () => {
        await this.connector
          .registerCustomer(email, password, name, code, geo, title, job)
          .then(async () => {
            this.loading = false;

            await this.registrationSuccessful(email, password);
          })
          .catch(async error => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerCustomer(
                email,
                password,
                name,
                code,
                geo,
                title,
                job,
                false
              );
            }

            this.loading = false;

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice cliente non valido"
              );
            }

            if (error instanceof AlreadyActiveError) {
              return await await this.alertService.error(
                this.viewRef,
                "Indirizzo email già registrato"
              );
            }

            if (error instanceof AlreadyRegisteredDifferentEmailError) {
              return this.alertService.error(
                this.viewRef,
                "Cliente già registrato con un indirizzo email differente"
              );
            }

            if (error instanceof SendEmailError) {
              return await this.registrationSuccessful(email, password);
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.registerCustomer(
            email,
            password,
            name,
            code,
            geo,
            title,
            job,
            false
          );
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  async registrationSuccessful(email: string, password: string) {
    // return await this.router.navigateByUrl(
    //   '/reseller-email-confirm',
    //   {
    //     state: {
    //       customer: JSON.stringify(this.customer),
    //       emailAddress: email
    //     }
    //   });
    this.toastr.success("Registrazione effettuata con successo.", "", {
      timeOut: 3000
    });
    await this.router.navigateByUrl("/reseller-login", {
      state: {
        emailAddress: email,
        password: password
      }
    });
  }

  openCampaignRulesModal() {
    this.modalDialogService.openDialog(this.viewRef, {
      title: "REGOLAMENTO",
      childComponent: ResellerCampaignTermsModalComponent,
      actionButtons: [
        {
          text: "Accetto",
          onAction: () => true
        }
      ]
    });
  }
}
