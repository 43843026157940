import { Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../services/alert.service";
import { ConnectorService } from "../../services/connector.service";
import { Router } from "@angular/router";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { AlreadyActiveError } from "../../errors/already-active.error";
import { NoCouponError } from "../../errors/no-coupon.error";
import { PersonalCodeInvalidError } from "../../errors/personal-code-invalid.error";
import { AuthenticationFailedError } from "../../errors/authentication-failed.error";
import { States } from "src/app/enums/states";
import { ModalDialogService } from "ngx-modal-dialog";
import { ResellerCampaignTermsModalComponent } from "../reseller-campaign-terms-modal/reseller-campaign-terms-modal.component";
import { RecaptchaComponent } from "ng-recaptcha";
import { NoCustomerError } from "src/app/errors";
import { OperationResult } from "src/app/models";
import { RECAPTCHA_SITE_KEY } from "src/app/app.settings";


@Component({
  selector: "app-code-request",
  templateUrl: "./code-request.component.html",
  styleUrls: ["./code-request.component.scss"]
})
export class CodeRequestComponent implements OnInit {

  loading = false;

  states = States;
  choosenState: string = States.ITALY;

  codeRequestForm = new FormGroup({
    personalCode: new FormControl(
      "",
      Validators.compose([
        Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
        Validators.required
      ])
    ),
    acceptance: new FormControl(false, Validators.requiredTrue),
    acceptancePolicy: new FormControl(false, Validators.requiredTrue),
    recaptchaReactive: new FormControl(null, Validators.required),
  });

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService,
    private modalDialogService: ModalDialogService
  ) {

  }



  ngOnInit() {


    this.codeRequestForm.controls.personalCode.valueChanges.subscribe(
      (value: string) => {
        if (value && value.toUpperCase() === "DEMO") {
          console.log(value);
          this.codeRequestForm.controls.personalCode.setErrors(null);
          this.codeRequestForm.controls.personalCode.clearValidators();
        } else {
          // Start commented by Francesco Torre
          /**
           * Next code lines prevent using validators set by "this.chooseState", and
           * always uses validator below instead.
           */

          // this.codeRequestForm.controls.personalCode.setValidators(
          //   Validators.compose([
          //     Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          //     Validators.required,
          //   ])
          // );
          // Ends commented by Francesco Torre
        }
      }
    );
  }

  async submitCodeRequestForm() {
    if (this.codeRequestForm.valid) {
      this.loading = true;

      var nation = this.GetStateAsInt(); // Added by Francesco Torre

      await this.RegisterConsumerRecaptcha(
        this.codeRequestForm.controls.personalCode.value,String(nation), this.codeRequestForm.controls.recaptchaReactive.value);
    }
  }

  public racaptchaSiteKey(){
    return RECAPTCHA_SITE_KEY;
  }

  private async RegisterConsumerRecaptcha(
    code: string,
    nation: string, // Added by Francesco Torre
    captcha: string,
    retry: boolean = true
  ) {

    if (code.toUpperCase() === "DEMO") {
      await this.router.navigateByUrl("/code-success", {
        state: {
          couponCode: "000000",
          personalCode: this.codeRequestForm.controls.personalCode.value.toUpperCase()
        }
      });
      return;
    }
    
    await this.connector
      .authenticate()
      .then(async () => {
        await this.connector

          .RegisterConsumerRecaptcha(code, nation, captcha)
          .then(async (result) => {
            this.loading = false;
            this.codeRequestForm.controls.recaptchaReactive.reset()
            await this.router.navigateByUrl(
              '/code-success',
              {
                state: {
                  couponCode: result.Result,
                  emailAddress: '',
                  phoneNumber: ''
                }
              });
          })
          .catch(async error => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.RegisterConsumerRecaptcha(code, nation, captcha, false);
            }

            this.loading = false;
            this.codeRequestForm.controls.recaptchaReactive.reset();
            if (error instanceof AlreadyActiveError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito è già stato utilizzato"
              );
            }

            if (error instanceof NoCouponError) {
              return await this.alertService.error(
                this.viewRef,
                "Promozione non valida"
              );
            }

            if (error instanceof PersonalCodeInvalidError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito non è valido"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async error => {
        if (error instanceof AuthenticationFailedError && retry) {

          return await this.RegisterConsumerRecaptcha(code, nation, captcha, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  chooseState(state: string) {
    this.choosenState = state;
    this.codeRequestForm.controls.personalCode.setValue(''); // Added by Francesco Torre
    if (state === States.ITALY) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i), // Commented by Francesco Torre
          Validators.required
        ])
      );
    } else if (state === States.SANMARINO) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          // Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i), // Commented by Francesco Torre
          Validators.pattern(/^[0-9]{3,6}$/i), // Added by Francesco Torre
          Validators.required
        ])
      );
    } else if (state === States.VATICAN) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          Validators.required
        ])
      );
    }
  }

  openCampaignRulesModal() {
    this.modalDialogService.openDialog(this.viewRef, {
      title: "REGOLAMENTO",
      childComponent: ResellerCampaignTermsModalComponent,
      actionButtons: [
        {
          text: "Accetto",
          onAction: () => true
        }
      ]
    });
  }

  private async checkSecurityCode(code: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.checkConsumerCode(code)
          .then(async (data: OperationResult<string>) => {

            this.loading = false;

            await this.router.navigateByUrl(
              '/code-success',
              {
                state: {
                  couponCode: data.Result,
                  emailAddress: '',
                  phoneNumber: ''
                }
              });

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.checkSecurityCode(code, false);
            }

            this.loading = false;

            if (error instanceof NoCouponError) {
              return await this.alertService.error(this.viewRef, 'Promozione non valida');
            }

            if (error instanceof PersonalCodeInvalidError) {
              return await this.alertService.error(this.viewRef, 'Il Codice Fiscale inserito non è valido');
            }

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(this.viewRef, 'Codice di conferma non valido');
            }

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      })
      .catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {
          return await this.checkSecurityCode(code, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

    /**
   * Written by Francesco Torre
   */
  GetStateAsInt(){

    switch (this.choosenState) {

      case States.ITALY:
        return 1;
        break;

      case States.VATICAN:
        return 2;
        break;

      case States.SANMARINO:
        return 3;
        break;

      default:
        return 0;
        break;
    }
  }

}
