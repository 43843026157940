import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectorService } from "../../services/connector.service";
import { AlertService } from "../../services/alert.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "../../services/storage.service";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { CustomerReturn, UserCredentials } from "src/app/models";
import {
  CouponNotValidError,
  PersonalCodeNotMatchError,
  CouponAlreadyUsedError,
  NoCouponError,
  CouponExpiredError,
} from "src/app/errors";

@Component({
  selector: "app-reseller-consumer-code-success",
  templateUrl: "./reseller-consumer-code-success.component.html",
  styleUrls: ["./reseller-consumer-code-success.component.scss"],
})
export class ResellerConsumerCodeSuccessComponent implements OnInit {
  loading = false;

  couponCode: string;
  emailAddress: string;
  phoneNumber: string;
  customer: CustomerReturn;
  personalCode: string;

  sendEmailForm = new FormGroup({
    emailAddress: new FormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
  });

  sendSmsForm = new FormGroup({
    phoneNumber: new FormControl("", Validators.required),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private connector: ConnectorService,
    private storageService: StorageService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) {
    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.couponCode = this.router.getCurrentNavigation().extras.state.couponCode;
        this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
        this.phoneNumber = this.router.getCurrentNavigation().extras.state.phoneNumber;
        this.personalCode = this.router.getCurrentNavigation().extras.state.personalCode;
      }
    });
  }

  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true,
      });
    }

    this.customer = this.storageService.getCustomer();
    if (!this.couponCode || this.couponCode === "") {
      await this.alertService.error(this.viewRef, "Codice coupon non valido");
      await this.router.navigateByUrl("/reseller-request-consumer-code", {
        replaceUrl: true,
      });
    }

    this.sendEmailForm.controls.emailAddress.setValue(this.emailAddress);
    this.sendSmsForm.controls.phoneNumber.setValue(this.phoneNumber);

    (window as any).dataLayer.push({
      event: "code.success.bonus35.farmacisti",
    });
  }

  async confirmRegistration() {
    if (this.couponCode && this.personalCode) {
      if (this.couponCode === "000000") {
        await this.alertService.success(
          this.viewRef,
          "Codice bonus registrato",
          () => {
            this.router.navigateByUrl("/reseller-dashboard");
            return true;
          }
        );
      } else {
        this.loading = true;
        await this.registerCoupon(this.couponCode, this.personalCode);
      }
    }
  }

  private async registerCoupon(
    couponCode: string,
    personalCode: string,
    retry: boolean = true
  ) {
    const credidentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .reAuthenticate(credidentials.token)
      .then(async () => {
        await this.connector
          .registerCoupon(couponCode, personalCode)
          .then(async () => {
            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              "Codice bonus registrato",
              () => {
                this.router.navigateByUrl("/reseller-dashboard");
                return true;
              }
            );
          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerCoupon(couponCode, personalCode, false);
            }

            this.loading = false;

            if (error instanceof CouponNotValidError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice bonus non valido"
              );
            }

            if (error instanceof PersonalCodeNotMatchError) {
              return await this.alertService.error(
                this.viewRef,
                "Il codice fiscale non corrisponde al codice bonus inserito"
              );
            }

            if (error instanceof CouponAlreadyUsedError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice bonus già utilizzato"
              );
            }

            if (
              error instanceof NoCouponError ||
              error instanceof CouponExpiredError
            ) {
              return await this.alertService.error(
                this.viewRef,
                "Promozione non valida"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.registerCoupon(couponCode, personalCode, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }
}
