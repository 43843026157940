import {Injectable, ViewContainerRef} from '@angular/core';
import {ModalDialogService} from 'ngx-modal-dialog';
import {AlertModalComponent} from '../components/alert-modal/alert-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private modalDialogService: ModalDialogService) { }

  public async success(viewRef: ViewContainerRef,
                       text: string,
                       proceed: () => boolean = () => true) {

    this.modalDialogService.openDialog(viewRef, {
      title: 'Successo',
      data: { text },
      childComponent: AlertModalComponent,
      actionButtons: [
        {
          text: 'OK',
          onAction: proceed
        }
      ]
    });

  }

  public async error(viewRef: ViewContainerRef,
                     text: string,
                     proceed: () => boolean = () => true) {

    this.modalDialogService.openDialog(viewRef, {
      title: 'Errore',
      data: { text },
      childComponent: AlertModalComponent,
      actionButtons: [
        {
          text: 'OK',
          onAction: proceed
        }
      ]
    });

  }

  public async confirm(viewRef: ViewContainerRef,
                       title: string = 'Conferma', text: string,
                       cancel: () => boolean = () => true,
                       proceed: () => boolean = () => true) {


    this.modalDialogService.openDialog(viewRef, {
      title: 'Conferma',
      data: { text },
      childComponent: AlertModalComponent,
      actionButtons: [
        {
          text: 'Annulla',
          onAction: cancel
        },
        {
          text: 'OK',
          onAction: proceed
        }
      ]
    });

  }

}
