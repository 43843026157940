import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AFFILIATE_PASSWORD,
  AFFILIATE_USERNAME,
  PROMOTION_ID,
  SERVICE_URL,
  STORE_ID
} from '../app.settings';
import { catchError, retry, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import {
  UnauthorizedError,
  RequestFailedError,
  GenericError,
  NotAuthenticatedError,
  NoCustomerError,
  AlreadyActiveError,
  AlreadyRegisteredDifferentEmailError,
  SendEmailError,
  NoCouponError,
  PersonalCodeInvalidError,
  CustomerNotActiveError,
  CouponNotValidError,
  CouponExpiredError,
  CouponAlreadyUsedError,
  PersonalCodeNotMatchError,
  AuthenticationFailedError,
  NotAuthorizedError
} from '../errors';
import {
  OperationResult,
  ErrorCode,
  AuthenticationReturn,
  CustomerReturn,
  VistaCouponUtilizzi,
  CouponRegistration,
  VistaCouponInvoices,
  CouponTotal,
  VistaCouponClientiGeo
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {


  constructor(private httpClient: HttpClient) { }

  // noinspection JSMethodCanBeStatic
  private handleHttpError(error: any): never {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 500) {
        Sentry.captureException(error);
      }

      throw error.status === 401
        ? new UnauthorizedError()
        : new RequestFailedError();
    }

    throw error;
  }

  private handleAuthResult(result: OperationResult<AuthenticationReturn>) {
    if (result.Result && result.Result.PromoActive) {
      if (result.Result.PromoActive.filter(p => p.Id == PROMOTION_ID).length > 0) {
        return result;
      }
    }

    throw new CustomerNotActiveError();
  }

  // noinspection JSMethodCanBeStatic
  private handleOperationResult(result: OperationResult<any>) {
    switch (result.Code) {
      case ErrorCode.GenericError:
        throw new GenericError();

      case ErrorCode.NotAuthenticated:
        throw new NotAuthenticatedError();

      case ErrorCode.NoCustomer:
        throw new NoCustomerError();

      case ErrorCode.AlreadyActive:
        throw new AlreadyActiveError(result.Result);

      case ErrorCode.AlreadyRegisteredDifferentEmail:
        throw new AlreadyRegisteredDifferentEmailError();

      case ErrorCode.ErrorSendEmail:
        throw new SendEmailError();

      case ErrorCode.NoCoupon:
        throw new NoCouponError();

      case ErrorCode.PersonalCodeInvalid:
        throw new PersonalCodeInvalidError();

      case ErrorCode.CustomerNotActive:
        throw new CustomerNotActiveError();

      case ErrorCode.CouponNotValid:
        throw new CouponNotValidError();

      case ErrorCode.CouponExpired:
        throw new CouponExpiredError();

      case ErrorCode.CouponAlreadyUsed:
        throw new CouponAlreadyUsedError();

      case ErrorCode.NotAuthorized:
        throw new NotAuthorizedError();

      case ErrorCode.PersonalCodeNotMatch:
        throw new PersonalCodeNotMatchError();
    }
  }

  public authenticate(): Promise<string> {
    const formData = new FormData();

    formData.append('username', AFFILIATE_USERNAME);
    formData.append('password', AFFILIATE_PASSWORD);
    formData.append('store', STORE_ID.toString());

    return this.httpClient
      .post<string>(`${SERVICE_URL}/Authenticate`, formData)
      .pipe(
        retry(1),
        tap((affiliateId: string) => {
          if (!affiliateId || affiliateId === '') {
            throw new AuthenticationFailedError();
          }
        }),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public authenticateCustomer(
    username: string,
    password: string
  ): Promise<OperationResult<AuthenticationReturn>> {
    const formData = new FormData();

    formData.append('userName', username);
    formData.append('password', password);
    formData.append('store', STORE_ID.toString());

    return this.httpClient
      .post<OperationResult<AuthenticationReturn>>(
        `${SERVICE_URL}/Coupon/Authenticate`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        tap(this.handleAuthResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public reAuthenticate(
    token: string
  ): Promise<OperationResult<AuthenticationReturn>> {
    const formData = new FormData();

    formData.append('token', token);
    formData.append('store', STORE_ID.toString());

    return this.httpClient
      .post<OperationResult<AuthenticationReturn>>(
        `${SERVICE_URL}/Coupon/Reauthenticate`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        tap(this.handleAuthResult),
        catchError(this.handleHttpError)
      )
      .toPromise().then();
  }


  public getCustomer(code: string): Promise<OperationResult<CustomerReturn>> {
    const formData = new FormData();

    formData.append('code', code);

    return this.httpClient
      .post<OperationResult<CustomerReturn>>(
        `${SERVICE_URL}/Coupon/GetCustomer`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public registerCustomer(
    email: string,
    password: string,
    name: string,
    code: string,
    geo: boolean,
    title: string,
    job: string
  ): Promise<OperationResult<CouponRegistration>> {
    const formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('code', code);
    formData.append('geo', geo ? 'true' : 'false');
    formData.append('title', title);
    formData.append('job', job);
    formData.append('source', '0');
    formData.append('couponID', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<CouponRegistration>>(
        `${SERVICE_URL}/Coupon/RegisterCustomer`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public resetCustomerPassword(
    email: string,
    code: string
  ): Promise<OperationResult<any>> {
    const formData = new FormData();

    formData.append('email', email);
    formData.append('code', code);
    formData.append('couponID', PROMOTION_ID.toString())

    return this.httpClient
      .post<OperationResult<any>>(
        `${SERVICE_URL}/Coupon/PasswordForgotten`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public requestNewCustomer(
    company: string,
    vatNumber: string,
    sdiCode: string,
    pec: string,
    address: string,
    city: string,
    zip: string,
    state: string,
    phone: string,
    email: string
  ): Promise<OperationResult<CustomerReturn>> {
    const formData = new FormData();

    formData.append('company', company);
    formData.append('vatnumber', vatNumber);
    formData.append('sdicode', sdiCode);
    formData.append('pec', pec);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('zip', zip);
    formData.append('state', state);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('couponID', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<CustomerReturn>>(
        `${SERVICE_URL}/Coupon/RequestNewCustomer`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public requestCustomerCode(
    company: string,
    vatNumber: string,
    name: string,
    email: string,
    phone: string
  ): Promise<OperationResult<any>> {
    const formData = new FormData();

    formData.append('company', company);
    formData.append('vatnumber', vatNumber);
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('couponID', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<any>>(
        `${SERVICE_URL}/Coupon/RequestCustomerCode`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public registerConsumer(
    personalCode: string,
    email: string,
    phone: string,
    nation: string,
    captcha: string,
  ): Promise<OperationResult<string>> {
    const formData = new FormData();

    formData.append('couponId', PROMOTION_ID.toString());
    formData.append('personalCode', personalCode.toUpperCase());
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('source', '0');
    formData.append('nation', nation);
    formData.append('captcha',captcha)
    return this.httpClient
      .post<OperationResult<string>>(
        `${SERVICE_URL}/Coupon/RegisterConsumer`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public RegisterConsumerRecaptcha(
    personalCode: string,
    nation: string,
    captcha: string,
  ): Promise<OperationResult<string>> {
    const formData = new FormData();

    formData.append('couponId', PROMOTION_ID.toString());
    formData.append('personalCode', personalCode.toUpperCase());
    formData.append('source', '0');
    formData.append('nation', nation);
    formData.append('captcha',captcha)
    return this.httpClient
      .post<OperationResult<string>>(
        `${SERVICE_URL}/Coupon/RegisterConsumerRecaptcha`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public checkConsumerCode(code: string): Promise<OperationResult<string>> {
    const formData = new FormData();

    formData.append('couponId', PROMOTION_ID.toString());
    formData.append('code', code);

    return this.httpClient
      .post<OperationResult<string>>(
        `${SERVICE_URL}/Coupon/CheckConsumerCode`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public sendCode(
    code: string,
    email: string,
    phone: string
  ): Promise<OperationResult<string>> {
    const formData = new FormData();

    formData.append('code', code);
    formData.append('email', email);
    formData.append('phone', phone);

    return this.httpClient
      .post<OperationResult<string>>(`${SERVICE_URL}/Coupon/SendCode`, formData)
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public registerCoupon(
    code: string,
    personalCode: string
  ): Promise<OperationResult<any>> {
    const formData = new FormData();

    formData.append('couponCode', code);
    formData.append('personalCode', personalCode.toUpperCase());
    formData.append('couponId', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<any>>(
        `${SERVICE_URL}/Coupon/RegisterCoupon`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getCouponsUsage(): Promise<OperationResult<VistaCouponUtilizzi[]>> {
    const formData = new FormData();

    formData.append('couponId', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<VistaCouponUtilizzi[]>>(
        `${SERVICE_URL}/Coupon/GetCouponsUsage`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getCustomerUsers(): Promise<OperationResult<CouponRegistration[]>> {
    const formData = new FormData();

    formData.append('couponId', PROMOTION_ID.toString());
    return this.httpClient
      .post<OperationResult<CouponRegistration[]>>(
        `${SERVICE_URL}/Coupon/GetCustomerUsers`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public removeCustomerUser(
    registrationId: number
  ): Promise<OperationResult<any>> {
    const formData = new FormData();

    formData.append('registrationId', registrationId.toString());

    return this.httpClient
      .post<OperationResult<any>>(
        `${SERVICE_URL}/Coupon/RemoveCustomerUser`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public changeUserPassword(
    code: string,
    email: string,
    password: string
  ): Promise<OperationResult<any>> {
    const formData = new FormData();

    formData.append('code', code);
    formData.append('email', email);
    formData.append('password', password);

    return this.httpClient
      .post<OperationResult<CouponRegistration[]>>(
        `${SERVICE_URL}/Coupon/ChangePassword`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getCouponsInvoices(): Promise<OperationResult<VistaCouponInvoices[]>> {
    const formData = new FormData();
    formData.append('couponId', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<VistaCouponUtilizzi[]>>(
        `${SERVICE_URL}/Coupon/GetCouponsInvoices`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getCouponsTotal(): Promise<OperationResult<CouponTotal>> {
    const formData = new FormData();
    formData.append('couponId', PROMOTION_ID.toString());

    return this.httpClient
      .post<OperationResult<CouponTotal>>(
        `${SERVICE_URL}/Coupon/GetCouponsTotal`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getCustomers(): Promise<OperationResult<VistaCouponClientiGeo[]>> {
    const formData = new FormData();
    formData.append('couponId', PROMOTION_ID.toString());
    return this.httpClient

      .post<OperationResult<VistaCouponClientiGeo[]>>(
        `${SERVICE_URL}/Coupon/GetCustomers`,
        formData
      )
      .pipe(
        retry(1),
        tap(this.handleOperationResult),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }

  public getImage(promoId: number): Promise<Blob> {
    const formData = new FormData();
    formData.append('couponId', promoId.toString());
    return this.httpClient
      .post<Blob>(
        `${SERVICE_URL}/Coupon/GetImage`,
        formData, {
        responseType: 'blob' as 'json',
      }
      )
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      )
      .toPromise();
  }
}
