import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-reseller-registration",
  templateUrl: "./reseller-registration.component.html",
  styleUrls: ["./reseller-registration.component.scss"]
})
export class ResellerRegistrationComponent implements OnInit {
  registrationForm = new FormGroup(
    {
      company: new FormControl("", Validators.required),
      street: new FormControl("", Validators.required),
      postcode: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      province: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      emailConfirm: new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      ),
      vatNumber: new FormControl("", Validators.required),
      sdiCode: new FormControl("", Validators.required),
      pec: new FormControl("", Validators.email)
    },
    (control: AbstractControl) => {
      if (control.get("email").value !== control.get("emailConfirm").value) {
        control.get("emailConfirm").setErrors({ match: true });
      }

      return null;
    }
  );

  constructor(private router: Router) {}

  ngOnInit() {}

  async submitRegistrationForm() {
    if (this.registrationForm.valid) {
      await this.router.navigateByUrl("/reseller-registration-confirm", {
        state: {
          registrationFormValues: JSON.stringify(this.registrationForm.value)
        }
      });
    }
  }
}
