export * from './authentication-return';
export * from './coordinates';
export * from './coupon-registration';
export * from './coupon-return';
export * from './coupon-total';
export * from './coupon-usage';
export * from './customer-return';
export * from './error-code';
export * from './operation-result';
export * from './user-credentials';
export * from './vista-coupon';
export * from './vista-coupon-clienti-geo';
export * from './vista-coupon-invoices';
export * from './vista-coupon-prodotti';
export * from './vista-coupon-prodotti-disponibilita';
export * from './vista-coupon-utilizzi';
