export class VistaCoupon {

    constructor(public AddressID: number,
                public CustomerID: number,
                public Company: string,
                public AddressOriginalCode: string,
                public CustomerOriginalCode: string,
                public CouponID: number,
                public StartDate: Date,
                public EndDate: Date,
                public Description: string,
                public CouponType: number,
                public CouponValue: number,
                public OriginalCode: string) { }

}
