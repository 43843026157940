import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConnectorService} from '../../services/connector.service';
import {AlertService} from '../../services/alert.service';
import {AuthenticationFailedError} from '../../errors/authentication-failed.error';
import {NoCustomerError} from '../../errors/no-customer.error';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import {CustomerNotActiveError} from "../../errors/customer-not-active.error";

@Component({
  selector: 'app-reseller-password-reset',
  templateUrl: './reseller-password-reset.component.html',
  styleUrls: ['./reseller-password-reset.component.scss']
})
export class ResellerPasswordResetComponent implements OnInit {

  loading = false;

  passwordResetForm = new FormGroup({
    code: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
  });

  constructor(private router: Router,
              private connector: ConnectorService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) { }

  ngOnInit() { }

  async submitPasswordResetForm() {

    if (this.passwordResetForm.valid) {

      this.loading = true;

      await this.resetPassword(
        this.passwordResetForm.controls.email.value,
        this.passwordResetForm.controls.code.value);

    }

  }

  private async resetPassword(email: string, code: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.resetCustomerPassword(email, code)
          .then(async () => {

            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              'Richiesta inviata correttamente',
              () => {
                this.router.navigateByUrl('/reseller-login', { replaceUrl: true });
                return true;
              });

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.resetPassword(email, code, false);
            }

            this.loading = false;

            if (error instanceof CustomerNotActiveError) {
              return await this.alertService.error(this.viewRef, 'Cliente non attivo');
            }

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(this.viewRef, 'Codice cliente non valido');
            }

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      }).catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {
          return await this.resetPassword(email, code, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
