import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConnectorService } from "../../services/connector.service";
import { StorageService } from "../../services/storage.service";
import { Router } from "@angular/router";
import { AlertService } from "../../services/alert.service";
import { OperationResult } from "../../models/operation-result";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { AlreadyActiveError } from "../../errors/already-active.error";
import { NoCouponError } from "../../errors/no-coupon.error";
import { PersonalCodeInvalidError } from "../../errors/personal-code-invalid.error";
import { AuthenticationFailedError } from "src/app/errors";
import { UserCredentials } from "src/app/models";
import { States } from "src/app/enums/states";

@Component({
  selector: "app-reseller-request-consumer-code",
  templateUrl: "./reseller-request-consumer-code.component.html",
  styleUrls: ["./reseller-request-consumer-code.component.scss"],
})
export class ResellerRequestConsumerCodeComponent implements OnInit {
  loading = false;
  emailAddress: string;
  phoneNumber: string;
  couponCode: string;
  currentUser: UserCredentials;
  states = States;
  choosenState: string = States.ITALY;

  codeRequestForm = new FormGroup({
    personalCode: new FormControl(
      "",
      Validators.compose([
        Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
        Validators.required,
      ])
    ),
    acceptance: new FormControl(false, Validators.requiredTrue),
    sendEmail: new FormControl(false),
    emailAddress: new FormControl("", Validators.compose([Validators.email])),
    sendSms: new FormControl(false),
    phoneNumber: new FormControl(""),
  });

  constructor(
    private router: Router,
    private connector: ConnectorService,
    private storageService: StorageService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) {}

  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true,
      });
    }

    this.currentUser = this.storageService.getCredentials();
    this.codeRequestForm.controls.personalCode.valueChanges.subscribe(
      (value: string) => {
        if (value && value.toUpperCase() === "DEMO") {
          console.log(value);
          this.codeRequestForm.controls.personalCode.setErrors(null);
          this.codeRequestForm.controls.personalCode.clearValidators();
        } else {
          // Start commented by Francesco Torre
          /**
           * Next code lines prevent using validators set by "this.chooseState", and
           * always uses validator below instead.
           */

          // this.codeRequestForm.controls.personalCode.setValidators(
          //   Validators.compose([
          //     Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          //     Validators.required,
          //   ])
          // );
          // Ends commented by Francesco Torre
        }
      }
    );

    // this.codeRequestForm.controls.sendEmail.valueChanges.subscribe(
    //   (value: boolean) => {
    //     if (value) {
    //       this.codeRequestForm.controls.emailAddress.setValidators([
    //         Validators.email,
    //         Validators.required
    //       ]);
    //     } else {
    //       this.codeRequestForm.controls.emailAddress.clearValidators();

    //       if (!this.codeRequestForm.controls.sendSms.value) {
    //         this.codeRequestForm.controls.sendSms.setValue(true);
    //       }
    //     }

    //     this.codeRequestForm.controls.emailAddress.updateValueAndValidity();
    //   }
    // );

    // this.codeRequestForm.controls.sendSms.valueChanges.subscribe(
    //   (value: boolean) => {
    //     if (value) {
    //       this.codeRequestForm.controls.phoneNumber.setValidators([
    //         Validators.required
    //       ]);
    //     } else {
    //       this.codeRequestForm.controls.phoneNumber.clearValidators();

    //       if (!this.codeRequestForm.controls.sendEmail.value) {
    //         this.codeRequestForm.controls.sendEmail.setValue(true);
    //       }
    //     }

    //     this.codeRequestForm.controls.phoneNumber.updateValueAndValidity();
    //   }
    // );
    (window as any).dataLayer.push({ event: "code.success.bonus35.reseller" });
  }

  async submitCodeRequestForm() {
    if (this.codeRequestForm.valid) {
      this.loading = true;

      var nation = this.GetStateAsInt(); // Added by Francesco Torre

      await this.registerConsumer(
        this.codeRequestForm.controls.personalCode.value,
        this.codeRequestForm.controls.sendEmail.value
          ? this.codeRequestForm.controls.emailAddress.value
          : "",
        this.codeRequestForm.controls.sendSms.value
          ? this.codeRequestForm.controls.phoneNumber.value
          : "",
          String(nation) // Added by Francesco Torre
      );
    }
  }

  private async registerConsumer(
    code: string,
    email: string,
    phone: string,
    nation: string, // Added by Francesco Torre
    retry: boolean = true
  ) {
    if (code.toUpperCase() === "DEMO") {
      await this.router.navigateByUrl("/reseller-consumer-code-success", {
        state: {
          couponCode: "000000",
          emailAddress: email,
          phoneNumber: phone,
          personalCode: this.codeRequestForm.controls.personalCode.value.toUpperCase(),
        },
      });
      return;
    }
    const credidentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .authenticateCustomer(credidentials.email, credidentials.password)
      .then(async () => {
        await this.connector

          
          .registerConsumer(code, email, phone, nation,"") // Edited by Francesco Torre

          .then(async (result: OperationResult<any>) => {
            this.loading = false;

            this.couponCode = result.Result;
            await this.router.navigateByUrl("/reseller-consumer-code-success", {
              state: {
                couponCode: result.Result,
                emailAddress: email,
                phoneNumber: phone,
                personalCode: this.codeRequestForm.controls.personalCode.value,
              },
            });

            if (email || phone) {
              await this.sendCode(this.couponCode, email, phone);
            }
          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerConsumer(code, email, phone, nation, false); // Edited by Francesco Torre
            }

            this.loading = false;

            if (error instanceof AlreadyActiveError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito è già stato utilizzato"
              );
            }

            if (error instanceof NoCouponError) {
              return await this.alertService.error(
                this.viewRef,
                "Promozione non valida"
              );
            }

            if (error instanceof PersonalCodeInvalidError) {
              return await this.alertService.error(
                this.viewRef,
                "Il Codice Fiscale inserito non è valido"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.registerConsumer(code, email, phone, nation, false); // Edited by Francesco Torre
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  private async sendCode(
    code: string,
    email: string,
    phone: string,
    retry: boolean = true
  ) {
    const credidentials: UserCredentials = this.storageService.getCredentials();
    await this.connector
      .authenticateCustomer(credidentials.email, credidentials.password)
      .then(async () => {
        await this.connector
          .sendCode(code, email, phone)
          .then(async () => {
            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              "Codice inviato correttamente"
            );
          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.sendCode(code, email, phone, false);
            }

            this.loading = false;

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof AuthenticationFailedError && retry) {
          return await this.sendCode(code, email, phone, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }

  // FRNBGR90M29Z130S San Marino
  // BNGFNC90M29Z106B Vatican

  chooseState(state: string) {
    this.choosenState = state;
    this.codeRequestForm.controls.personalCode.setValue(''); // Added by Francesco Torre
    if (state === States.ITALY) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          Validators.required,
        ])
      );
    } else if (state === States.SANMARINO) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          // Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i), // Commented by Francesco Torre
          Validators.pattern(/^[0-9]{3,6}$/i), // Added by Francesco Torre
          Validators.required,
        ])
      );
    } else if (state === States.VATICAN) {
      this.codeRequestForm.controls.personalCode.setValidators(
        Validators.compose([
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          Validators.required,
        ])
      );
    }
  }

  /**
   * Written by Francesco Torre
   */
  GetStateAsInt(){

    switch (this.choosenState) {

      case States.ITALY:
        return 1;
        break;

      case States.VATICAN:
        return 2;
        break;

      case States.SANMARINO:
        return 3;
        break;

      default:
        return 0;
        break;
    }
  }

} // End class ResellerRequestConsumerCodeComponent implements OnInit
