import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectorService } from "../../services/connector.service";
import { StorageService } from "../../services/storage.service";
import { AlertService } from "../../services/alert.service";
import { NotAuthenticatedError } from "../../errors/not-authenticated.error";
import { CouponNotValidError } from "../../errors/coupon-not-valid.error";
import { PersonalCodeNotMatchError } from "../../errors/personal-code-not-match.error";
import { CouponAlreadyUsedError } from "../../errors/coupon-already-used.error";
import { CouponExpiredError } from "../../errors/coupon-expired.error";
import { NoCouponError } from "../../errors/no-coupon.error";

@Component({
  selector: "app-reseller-verify-consumer-code",
  templateUrl: "./reseller-verify-consumer-code.component.html",
  styleUrls: ["./reseller-verify-consumer-code.component.scss"],
})
export class ResellerVerifyConsumerCodeComponent implements OnInit {
  loading = false;

  verifyConsumerCodeForm = new FormGroup({
    couponCode: new FormControl("", [
      Validators.required,
      Validators.maxLength(12),
    ]),
    personalCode: new FormControl(
      "",
      Validators.compose([
        Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
      ])
    ),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private connector: ConnectorService,
    private storageService: StorageService,
    private viewRef: ViewContainerRef,
    private alertService: AlertService
  ) {
    this.route.queryParams.subscribe(() => {
      // this.verifyConsumerCodeForm.controls.personalCode.valueChanges.subscribe(
      //   (value: string) => {
      //     if (value && value.toUpperCase() === "DEMO") {
      //       console.log(value);
      //       this.verifyConsumerCodeForm.controls.personalCode.setErrors(null);
      //       this.verifyConsumerCodeForm.controls.personalCode.clearValidators();
      //     } else {
      //       this.verifyConsumerCodeForm.controls.personalCode.setValidators(
      //         Validators.compose([
      //           Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
      //           Validators.required
      //         ])
      //       );
      //     }
      //   }
      // );
      if (this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras.state.personalCode) {
          this.verifyConsumerCodeForm.controls.personalCode.setValue(
            this.router.getCurrentNavigation().extras.state.personalCode
          );
        }
        this.verifyConsumerCodeForm.controls.couponCode.setValue(
          this.router.getCurrentNavigation().extras.state.couponCode
        );
      }
    });
  }

  async ngOnInit() {
    if (!(await this.storageService.hasCredentials())) {
      return await this.router.navigateByUrl("/reseller-access", {
        replaceUrl: true,
      });
    }
    (window as any).dataLayer.push({
      event: "code.success.bonus35.farmacisti",
    });
  }

  async submitVerifyConsumerCodeForm() {
    if (this.verifyConsumerCodeForm.valid) {
      if (this.verifyConsumerCodeForm.controls.couponCode.value === "000000") {
        await this.alertService.success(
          this.viewRef,
          "DEMO: Codice bonus registrato",
          () => {
            this.router.navigateByUrl("/reseller-dashboard");
            return true;
          }
        );
      } else {
        this.loading = true;

        await this.registerCoupon(
          this.verifyConsumerCodeForm.controls.couponCode.value,
          this.verifyConsumerCodeForm.controls.personalCode.value
        );
      }
    }
  }

  private async registerCoupon(
    couponCode: string,
    personalCode: string,
    retry: boolean = true
  ) {
    const credentials = await this.storageService.getCredentials();
    console.log(couponCode, personalCode);
    await this.connector
      .reAuthenticate(credentials.token)
      .then(async () => {
        await this.connector
          .registerCoupon(couponCode, personalCode)
          .then(async () => {
            this.loading = false;

            await this.alertService.success(
              this.viewRef,
              "Codice bonus registrato",
              () => {
                this.router.navigateByUrl("/reseller-dashboard");
                return true;
              }
            );
          })
          .catch(async (error) => {
            if (error instanceof NotAuthenticatedError && retry) {
              return await this.registerCoupon(couponCode, personalCode, false);
            }

            this.loading = false;

            if (error instanceof CouponNotValidError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice bonus non valido"
              );
            }

            if (error instanceof PersonalCodeNotMatchError) {
              return await this.alertService.error(
                this.viewRef,
                "Il codice fiscale non corrisponde al codice bonus inserito"
              );
            }

            if (error instanceof CouponAlreadyUsedError) {
              return await this.alertService.error(
                this.viewRef,
                "Codice bonus già utilizzato"
              );
            }

            if (
              error instanceof NoCouponError ||
              error instanceof CouponExpiredError
            ) {
              return await this.alertService.error(
                this.viewRef,
                "Promozione non valida"
              );
            }

            await this.alertService.error(
              this.viewRef,
              "Errore durante il caricamento"
            );
          });
      })
      .catch(async (error) => {
        if (error instanceof NotAuthenticatedError && retry) {
          return await this.registerCoupon(couponCode, personalCode, false);
        }

        this.loading = false;

        await this.alertService.error(
          this.viewRef,
          "Errore durante il caricamento"
        );
      });
  }
}
