import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor() { }

  /**
   * Calculates the distance between two points on the surface of a spheroid using the Vincenty formula.
   *
   * @source https://en.wikipedia.org/wiki/Vincenty%27s_formulae
   *
   * @param latFrom Latitude of start point in decimal degrees
   * @param lngFrom Longitude of start point in decimal degrees
   * @param latTo Latitude of target point in decimal degrees
   * @param lngTo Longitude of target point in decimal degrees
   * @param spheroidRadius Spheroid radius in meters
   *
   * @return Distance between points in meters
   */
  public distance(latFrom: number, lngFrom: number,
                  latTo: number, lngTo: number,
                  spheroidRadius: number = 6371000.00): number {

    latFrom = this.deg2rad(latFrom);
    lngFrom = this.deg2rad(lngFrom);
    latTo = this.deg2rad(latTo);
    lngTo = this.deg2rad(lngTo);

    const lngDelta = lngTo - lngFrom;

    const a = Math.pow(Math.cos(latTo) * Math.sin(lngDelta), 2) +
      Math.pow(Math.cos(latFrom) * Math.sin(latTo) - Math.sin(latFrom) * Math.cos(latTo) * Math.cos(lngDelta), 2);

    const b = Math.sin(latFrom) * Math.sin(latTo) + Math.cos(latFrom) * Math.cos(latTo) * Math.cos(lngDelta);

    const angle = Math.atan2(Math.sqrt(a), b);

    return angle * spheroidRadius;

  }

  /**
   * Converts numeric degrees to radians
   *
   * @param value Value to convert in degrees
   *
   * @return Converted value in radians
   */
  private deg2rad = (value: number): number => value * Math.PI / 180;

}
