import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ConnectorService} from '../../services/connector.service';
import {AlertService} from '../../services/alert.service';
import {StorageService} from '../../services/storage.service';
import {OperationResult} from '../../models/operation-result';
import {NotAuthenticatedError} from '../../errors/not-authenticated.error';
import {NoCouponError} from '../../errors/no-coupon.error';
import {PersonalCodeInvalidError} from '../../errors/personal-code-invalid.error';
import {NoCustomerError} from '../../errors/no-customer.error';
import {AuthenticationFailedError} from '../../errors/authentication-failed.error';

@Component({
  selector: 'app-reseller-verify-security-code',
  templateUrl: './reseller-verify-security-code.component.html',
  styleUrls: ['./reseller-verify-security-code.component.scss']
})
export class ResellerVerifySecurityCodeComponent implements OnInit {

  loading = false;

  emailAddress: string;
  phoneNumber: string;

  checkSecurityCodeForm = new FormGroup({
    securityCode: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private connector: ConnectorService,
              private storageService: StorageService,
              private viewRef: ViewContainerRef,
              private alertService: AlertService) {

    this.route.queryParams.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
        this.phoneNumber = this.router.getCurrentNavigation().extras.state.phoneNumber;
      }
    });

  }

  async ngOnInit() {

    if (!await this.storageService.hasCredentials()) {
      return await this.router.navigateByUrl('/reseller-access', { replaceUrl: true });
    }

  }

  async submitCheckSecurityCodeForm() {

    if (this.checkSecurityCodeForm.valid) {
      this.loading = true;
      await this.checkSecurityCode(this.checkSecurityCodeForm.controls.securityCode.value);
    }

  }

  private async checkSecurityCode(code: string, retry: boolean = true) {

    await this.connector.authenticate()
      .then(async () => {

        await this.connector.checkConsumerCode(code)
          .then(async (data: OperationResult<string>) => {

            this.loading = false;

            await this.router.navigateByUrl(
              '/reseller-consumer-code-success',
              {
                state: {
                  couponCode: data.Result,
                  emailAddress: this.emailAddress,
                  phoneNumber: this.phoneNumber
                }
              });

          }).catch(async (error) => {

            if (error instanceof NotAuthenticatedError && retry) {
              return await this.checkSecurityCode(code, false);
            }

            this.loading = false;

            if (error instanceof NoCouponError) {
              return await this.alertService.error(this.viewRef, 'Promozione non valida');
            }

            if (error instanceof PersonalCodeInvalidError) {
              return await this.alertService.error(this.viewRef, 'Il Codice Fiscale inserito non è valido');
            }

            if (error instanceof NoCustomerError) {
              return await this.alertService.error(this.viewRef, 'Codice di conferma non valido');
            }

            await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

          });

      })
      .catch(async (error) => {

        if (error instanceof AuthenticationFailedError && retry) {
          return await this.checkSecurityCode(code, false);
        }

        this.loading = false;

        await this.alertService.error(this.viewRef, 'Errore durante il caricamento');

      });

  }

}
