import { CouponUsage } from './coupon-usage';
import { CouponRegistration } from './coupon-registration';
import { VistaCouponProdotti } from './vista-coupon-prodotti';


export class CouponReturn {

    constructor(public Usage: CouponUsage,
                public Registration: CouponRegistration,
                public Products: VistaCouponProdotti[]) { }

}
