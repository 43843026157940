import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-promo-button',
  templateUrl: './promo-button.component.html',
  styleUrls: ['./promo-button.component.scss']
})
export class PromoButtonComponent implements OnInit {
  public loading = false;
  public logged = false;

  @Input() public alwaysHide = false;

  constructor( private viewRef: ViewContainerRef,
    private navigation: NavigationService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.logged = !this.alwaysHide && this.storageService.hasCredentials();
  }

  public async goToPromoList(retry: boolean = true) {
    this.loading = true;
    await this.navigation.goToPromoList(this.viewRef);
    this.loading = false;
  }
}
