import { Injectable } from '@angular/core';
import { UserCredentials } from '../models/user-credentials';
import { CouponRegistration } from '../models/coupon-registration';
import { CustomerReturn } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  // noinspection JSMethodCanBeStatic
  public getCredentials(): UserCredentials {
    return new UserCredentials(
      localStorage.getItem('reseller_email'),
      localStorage.getItem('reseller_code'),      
      atob(sessionStorage.getItem('reseller_password')),
      localStorage.getItem('reseller_token')
    );
  }

  // noinspection JSMethodCanBeStatic
  public setCredentials(credentials: UserCredentials) {
    localStorage.setItem('reseller_email', credentials.email);
    localStorage.setItem('reseller_code', credentials.code);
    localStorage.setItem('reseller_token', credentials.token);
    sessionStorage.setItem('reseller_password', btoa(credentials.password));
  }

  public saveCustomer(customer: CustomerReturn): void {
    const customerString = JSON.stringify(customer);
    localStorage.setItem('customer', customerString);
  }

  public getCustomer(): CustomerReturn {
    const customer = localStorage.getItem('customer');
    if (customer) {
      return JSON.parse(customer);
    } else {
      return null;
    }
  }

  public hasCredentials(): boolean {
    const credentials = this.getCredentials();

    return (
      credentials.email &&
      credentials.email !== '' &&
      ((credentials.password &&
      credentials.password !== '') 
      || credentials.token &&
      credentials.token !== '' )  &&
      credentials.code &&
      credentials.code !== ''     
    );
  }

  // noinspection JSMethodCanBeStatic
  public clearCredentials() {
    localStorage.removeItem('reseller_email');
    localStorage.removeItem('reseller_code');
    sessionStorage.removeItem('reseller_password');
    localStorage.removeItem('reseller_token');
  }

  // noinspection JSMethodCanBeStatic
  public getCurrentUser(): CouponRegistration {
    const value = sessionStorage.getItem('current_user');
    return value && value.trim()
      ? JSON.parse(sessionStorage.getItem('current_user'))
      : null;
  }

  // noinspection JSMethodCanBeStatic
  public setCurrentUser(user: CouponRegistration) {
    sessionStorage.setItem('current_user', JSON.stringify(user));
  }

  // noinspection JSMethodCanBeStatic
  public clearCurrentUser() {
    sessionStorage.removeItem('current_user');
  }
}
