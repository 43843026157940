export class VistaCouponUtilizzi {

    constructor(public CouponID: number,
                public CouponCode: string,
                public UsageDate: Date,
                public UsageType: number,
                public DiscountedValue: number,
                public RegistrationID: number,
                public Name: string,
                public PersonalCode: string,
                public Email: string,
                public Phone: string,
                public RegistrationDate: Date,
                public OrderNumber: number,
                public OrderTotal: number,
                public OrderDate: Date,
                public OriginalCode: string,
                public OrderReference: string,
                public Title: string,
                public Job: string,
                public IsAdmin: boolean,
                public AddressID: number) { }

}
