import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reseller-registration-success',
  templateUrl: './reseller-registration-success.component.html',
  styleUrls: ['./reseller-registration-success.component.scss']
})
export class ResellerRegistrationSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
