import {VistaCouponProdottiDisponibilita} from './vista-coupon-prodotti-disponibilita';

export class CouponTotal {

    constructor(public UsedCoupon: number,
                public RegisteredCoupon: number,
                public InvoicedCoupon: number,
                public AvailableCoupon: VistaCouponProdottiDisponibilita[]) { }

}
