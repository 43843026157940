import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {CampaignInfoComponent} from './components/campaign-info/campaign-info.component';
import {CodeRequestComponent} from './components/code-request/code-request.component';
import {SecurityCodeComponent} from './components/security-code/security-code.component';
import {CodeSuccessComponent} from './components/code-success/code-success.component';
import {ResellersListComponent} from './components/resellers-list/resellers-list.component';
import {ResellerDetailComponent} from './components/reseller-detail/reseller-detail.component';
import {ResellerAccessComponent} from './components/reseller-access/reseller-access.component';
import {ResellerLoginComponent} from './components/reseller-login/reseller-login.component';
import {ResellerRegistrationComponent} from './components/reseller-registration/reseller-registration.component';
import {ResellerCreateFirstUserComponent} from './components/reseller-create-first-user/reseller-create-first-user.component';
import {CampaignRulesComponent} from './components/campaign-rules/campaign-rules.component';
import {ResellerPasswordResetComponent} from './components/reseller-password-reset/reseller-password-reset.component';
import {ResellerEmailConfirmComponent} from './components/reseller-email-confirm/reseller-email-confirm.component';
import {ResellerDashboardComponent} from './components/reseller-dashboard/reseller-dashboard.component';
import {ResellerRequestCodeComponent} from './components/reseller-request-code/reseller-request-code.component';
import {ResellerRegistrationConfirmComponent} from './components/reseller-registration-confirm/reseller-registration-confirm.component';
import {ResellerRegistrationSuccessComponent} from './components/reseller-registration-success/reseller-registration-success.component';
import {ResellerManageUsersComponent} from './components/reseller-manage-users/reseller-manage-users.component';
import {ResellerRequestConsumerCodeComponent} from './components/reseller-request-consumer-code/reseller-request-consumer-code.component';
import {ResellerHelpComponent} from './components/reseller-help/reseller-help.component';
import {ResellerVerifyConsumerCodeComponent} from './components/reseller-verify-consumer-code/reseller-verify-consumer-code.component';
import {ResellerBonusDetailsComponent} from './components/reseller-bonus-details/reseller-bonus-details.component';
import {ResellerChangePasswordComponent} from './components/reseller-change-password/reseller-change-password.component';
import {ResellerVerifySecurityCodeComponent} from './components/reseller-verify-security-code/reseller-verify-security-code.component';
import {ResellerConsumerCodeSuccessComponent} from './components/reseller-consumer-code-success/reseller-consumer-code-success.component';
import { PromoListComponent } from './components/promo-list/promo-list.component';
import { VideoTutorialComponent } from './components/video-tutorial/video-tutorial.component';
import { VideoTutorialListComponent } from './components/video-tutorial-list/video-tutorial-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'campaign-info',
    component: CampaignInfoComponent
  },
  {
    path: 'code-request',
    component: CodeRequestComponent
  },
  {
    path: 'security-code',
    component: SecurityCodeComponent
  },
  {
    path: 'code-success',
    component: CodeSuccessComponent
  },
  {
    path: 'resellers-list',
    component: ResellersListComponent
  },
  {
    path: 'reseller-detail',
    component: ResellerDetailComponent
  },
  {
    path: 'reseller-access',
    component: ResellerAccessComponent
  },
  {
    path: 'reseller-login',
    component: ResellerLoginComponent
  },
  {
    path: 'reseller-registration',
    component: ResellerRegistrationComponent
  },
  {
    path: 'reseller-create-first-user',
    component: ResellerCreateFirstUserComponent
  },
  {
    path: 'campaign-rules',
    component: CampaignRulesComponent
  },
  {
    path: 'reseller-password-reset',
    component: ResellerPasswordResetComponent
  },
  {
    path: 'reseller-email-confirm',
    component: ResellerEmailConfirmComponent
  },
  {
    path: 'reseller-dashboard',
    component: ResellerDashboardComponent
  },
  {
    path: 'reseller-request-code',
    component: ResellerRequestCodeComponent
  },
  {
    path: 'reseller-registration-confirm',
    component: ResellerRegistrationConfirmComponent
  },
  {
    path: 'reseller-registration-success',
    component: ResellerRegistrationSuccessComponent
  },
  {
    path: 'reseller-manage-users',
    component: ResellerManageUsersComponent
  },
  {
    path: 'reseller-request-consumer-code',
    component: ResellerRequestConsumerCodeComponent
  },
  {
    path: 'reseller-help',
    component: ResellerHelpComponent
  },
  {
    path: 'reseller-verify-consumer-code',
    component: ResellerVerifyConsumerCodeComponent
  },
  {
    path: 'reseller-bonus-details',
    component: ResellerBonusDetailsComponent
  },
  {
    path: 'reseller-change-password',
    component: ResellerChangePasswordComponent
  },
  {
    path: 'reseller-verify-security-code',
    component: ResellerVerifySecurityCodeComponent
  },
  {
    path: 'reseller-consumer-code-success',
    component: ResellerConsumerCodeSuccessComponent
  }
  ,
  {
    path: 'promo-list',
    component: PromoListComponent
  },
  {
    path: 'video-tutorial',
    component: VideoTutorialComponent
  },
  {
    path: 'video-tutorial-list',
    component: VideoTutorialListComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
